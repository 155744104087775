<template>
    <div class="container">
       <h1 class="imprintTitle">Imprint & Privacy Policy</h1>
       <p class="imprintBody">
            Yum Audio GmbH & Co KG<br/>
            Riefweg 21/4<br/>
            88045 Friedrichshafen<br/>
            Germany<br/>
            Vertreten durch Benedikt Adams, Jonas Obermüller und Markus Marschall<br/>
            <br/>
            info (at) yum-audio (dot) com<br/>
            <br/>
            <br/>
            Commercial Register: HRA 727539 District court Ulm
            <br/>
       </p>
        <h1 class="imprintTitle"> Privacy Policy </h1>
        <p class="imprintSubtitle"> Collection and Use of Personal Information </p>
        <p class="imprintBody">
            Personal information is data that can be used to uniquely identify or contact a specific individual. <br/>
            <br/>
            You may be asked to provide your personal information anytime you are in contact with us (like when you fill out our contact form or sign up for a newsletter). You are not required to provide the information we requested, but if you choose not to do so, in many cases this means we will not be able to provide you with our products or services or respond to your inquiries.<br/>
            <br/>
        </p>
        <p class="imprintSubtitle"> What personal information we collect </p>
        <p class="imprintBody">
            When you visit our website, connect to our services, contact us, use our software, or subscribe to our newsletter, we collect a variety of information, including your email address, device information, IP address, and a record of your communication.
        </p>
        <p class="imprintSubtitle"> How we use your personal information </p>
        <p class="imprintBody">
            We collect and process all your personal data in accordance with the relevant data protection regulations, in particular the General Data Protection Regulation (GDPR), the German Federal Data Protection Act (BDSG) and the German Telemedia Act (TMG). This means that we may process your personal information for the purposes described in this Privacy Policy with your consent, if it is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract, if it is necessary for compliance with a legal obligation to which Yum Audio is subject, or when we have assessed it is necessary for the purposes of the legitimate interests pursued by Yum Audio or by a third party to whom it may be necessary to disclose information.

            The personal information we collect allows us to keep you up to date on our latest product announcements, software updates, and services. You may at any time opt out of receiving such communications by contacting us. In particular, we only send you our newsletter with your prior consent, and you can opt out of receiving the newsletter anytime by clicking the unsubscribe link we include in each newsletter, or by contacting us.
            We also use the personal information we collect to help us create, develop, deliver, protect, and improve our products, services, content, and customer communications.
            We may use your personal information to send important notices, such as communications about changes to our terms, conditions, and policies. As this information is important to your interaction with Yum Audio you may not opt out of receiving these communications.
            We may also use personal information for internal purposes such as auditing, data analysis, and research to improve our products, services, and customer communications.
            Controller within the meaning of the data protection laws and service provider in the sense of the Telemedia Act is

            Yum Audio GmbH & Co KG
            Riefweg 21/4
            88045 Friedrichshafen
            Germany
        </p>
        <p class="imprintSubtitle"> Collection and Use of Non-Personal Information </p>
        <p class="imprintBody">
            Non-personal information is data that cannot, on its own, be used to uniquely identify a specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. We may combine personal and non-personal information for certain purposes; this data will then be treated as personal information for as long as it remains combined. Conversely, aggregated information, i.e. data that might have originated from personal information, but that has been processed in such a way as to not allow personal identification, is treated as non-personal information.
        </p>
        <p class="imprintSubtitle"> Cookies and Other Technologies </p>
        <p class="imprintBody">
            Our website, services, apps, email communications and advertisements may use “cookies” and other technologies such as “pixel tags” and “click-through URLs”.<br/>
            <br/>
            We use the information we collect in this manner to better understand our users' interaction with our website and to optimize the user experience. You can disable cookies in your browser settings, but please note that certain features on our website may not be available as a result.<br/>
            <br/>
            In our email communications, we may use other technologies like “pixel tags” and “click-through URLs” in order to determine if an email has been opened and which links have been clicked. We use this information to help us determine interest in particular topics and improve the effectiveness of our communications, and to reduce or eliminate messages sent to customers. Pixel tags are small images shown inside an HTML email; you can disable tracking by disabling HTML in your email client. A link with a click-through URL, when clicked, first sends the user to a web server which records the click, and then to the link’s destination. If you prefer not to be tracked in this way, you should not click text or graphic links in the email messages.<br/>
        </p>
        <p class="imprintSubtitle"> Disclosure to Third Parties </p>   
        <p class="imprintBody">
            We don't share personal information with anyone outside of Yum Audio, except for the few exceptions below.<br/>
            <br/>
            We work together with other companies who provide information processing services. We only share personal information with these companies if you have agreed to the transfer, or if it is permitted by data protection law. The information we share is limited to the data necessary for the third parties to provide their services.<br/>
            <br/>
            We use these companies for the following services: hosting of our website and support portal, providing software authorization services, sending out newsletters, analyzing our website traffic, manage our app beta testing, and processing our app crash reports. These companies are obligated to protect your information in accordance with data protection law and provide the necessary safeguards if they are outside of the EU. The companies are bound by our instructions, and are not allowed to use the shared data for any other purpose.<br/>
            <br/>
            We also share personal information if disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations thereof; to detect, prevent, or otherwise address fraud or security issues; and to protect against harm to the rights, property or safety of Yum Audio, its users or the public as required or permitted by law.<br/>
            <br/>
            If Yum Audio is involved in a reorganization, merger, or sale, the information we collect may be transferred as part of that transaction.<br/>
        </p>
        <p class="imprintSubtitle"> Protection of Personal Information </p>
        <p class="imprintBody">
            We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.<br/>
            <br/>
            Inside Yum Audio, we restrict access to personal information to only those employees who need to know that information in order to deploy and maintain our services. These individuals are bound by confidentiality agreements and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations.<br/>
        </p>
        <p class="imprintSubtitle"> Access to Personal Information </p>
        <p class="imprintBody">
            You are enimprintTitled to request information about the personal data stored by us, to have incorrect data corrected, or to request the freezing or deletion of your data.<br/>
            <br/>
            There are, however, cases where we are not allowed to delete your data in its entirety as a result of legal retention periods. We may also decline requests if they risk the privacy of others, would be extremely impractical, or for which access is not required by law.<br/>
            <br/>
            Your personal information is retained for as long as it is necessary in order to fulfill the purposes outlined in this Privacy Policy, to enforce applicable Terms of Service, or to comply with our legal obligations.<br/>
        </p>
        <p class="imprintSubtitle"> Third-Party Sites and Services </p>
        <p class="imprintBody">
            Yum Audio's website or any of our services may contain links to third-party websites or services. We are not responsible for the information collected by those third parties and we encourage you to read their privacy policy before submitting any personal information to them.
        </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Time Turner - Imprint'
  }
  </script>


<style>
html {
    display:flex;
    margin: 0px;
    padding: 0px;
}

.container {
  display: flex;
  align-items: left;
  flex-direction: column;
  /* justify-content: center; */
  background-color: rgb(20, 20, 20);
  margin: 0px;
  padding: 0px;
}

.imprintTitle {
    color: #ECECEC;
    font-weight: 1;
    letter-spacing: 2px;
    text-align: left;
    margin-left: 13vw;
    margin-top: 8vh;
    margin-bottom: 3vh;
}

.imprintBody {
    color: #ECECEC;
    font-weight: 1;
    letter-spacing: 2px;
    
    text-align: left;
    margin-left: 13vw;
    margin-top: 0vh;
    margin-right: 13vw;
    margin-bottom: 3vh;
}

.imprintSubtitle {
    color: #ECECEC;
    font-weight: 1;
    font-size: 24px;
    letter-spacing: 2px;
    text-align: left;
    margin-left: 13vw;
    margin-top: 1vh;
}

.imprintBody, .imprintSubTitle, .imprintTitle {
    font-family: 'DM Sans', sans-serif;
    letter-spacing: 0.5px;
}

</style>

  