import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import LandingPage from './components/LandingPage.vue';
import Imprint from './components/Imprint.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint
    },
  ],
});

createApp(App)
  .use(router)
  .mount('#app');
