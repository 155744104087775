<template>
  <div class="container">
    <div class="content">
      <h1 class="title">Time Turner</h1>
      <p class="subtitle">turning time soon</p>
    </div>
    <div class="imprintLink">
        <router-link class="imprint" to="/imprint">Imprint</router-link>
    </div>
  </div>
</template>

<!-- Rest of the file remains the same -->
<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: black;
  background-image: url('../assets/background.jpg');
}

.content {
  margin-top: 77vh; 
}

@media screen and (max-width: 768px) {
  .container {
    background-image: url('../assets/background.jpg');
    background-position: 35% 0%;
    background-size: cover;
  }

  .content {
    margin-top: 3vh;
    padding-top: 20px;
  }
}

.title {
  font-family: 'DM Sans', sans-serif;
  font-size: 36px;
  font-weight: 250;
  letter-spacing: 0.54px;
}
.subtitle {
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.3px;
}
.imprint {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 300; 
}

.imprintLink {
  position: fixed;
  bottom: 3vh;
}

.title, .subtitle, .imprint {
  color: #ECECEC;
}


</style>
