<template>
    <div id="app">
      <router-view/>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Time Turner - Storefront'
  }
  </script>
  
  <style>
  #app {
    margin: 0px;
    padding: 0px;
    font-family: 'DM Sans', sans-serif;
    color: #2c3e50;
    height: 100vh;
    text-align: center;
  }

  body {
    display: block;
    margin: 0px;
}
  </style>
  